import React, {useState, useContext, useEffect, useCallback} from 'react';
import {GoogleLogin, GoogleLogout, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import { useNavigate } from 'react-router-dom';

import {retrieveAuthToken, retrieveImpresonationToken} from '../data/TokenService'
import {trackAttributes, useBrowserDescription}  from './Track'

import {graphQLClient} from '../Config'

import * as LOG from 'loglevel';

// Set these up in the google cloud api console: https://console.cloud.google.com/apis/credentials?project=engaged-school-1550967497899
// See https://developers.google.com/identity/sign-in/web/sign-in for instructions for integrations (this is driven from react-google-login)
const CLIENT_ID = "600167240093-84fldr0ahqc3jmd5kimamdb1hvsclct3.apps.googleusercontent.com";

export type SupportedRoles = "admin" |
    //"student" |
    "participant" | "guest" | 
    "staff" | "advisor" | "leadership" | "counselor" 

interface AuthenticationScopeData {
    isAuthenticated: () => boolean | undefined
    login: (user: any, cold?: boolean) => void
    logout: () => void
    authenticateWithToken: (auth: GoogleLoginResponse | GoogleLoginResponseOffline) => void
    user: User | undefined
    roles: string[]
    //TODO: Set type for role string to known role types.
    hasRole: (role: SupportedRoles) => boolean
    userId: string | undefined
}

const DEFAULT_SCOPE: AuthenticationScopeData = {
    isAuthenticated: () => false,
    login: (user: any) => {throw new Error("AuthenticationScope is not initialized")},
    logout: () => {throw new Error("AuthenticationScope is not initialized")},
    authenticateWithToken: (_) => {},
    user: undefined,
    roles: [],
    hasRole: (role: SupportedRoles) => false,
    userId: undefined
}

export interface User {
    id: string
    roles: string[]
    authorizationToken: string|undefined
    firstName: string
    lastName: string
    email: string
    domain?: string
    expires: number
}

export interface UserCache {
    user?: User
    setUser: (user?: User) => void
}

// const UserCacheContext = React.createContext<UserCache>({
//     setUser: () => {}
// })

// export const useCachedUser = () => useContext(UserCacheContext)

// export const UserCacheScope = ({children}: {children: React.ReactNode}) => {

//     let [user, setUser] = useState<User|undefined>(undefined)
//     let context = {
//         user,
//         setUser //: useCallback((user?: User) => setUser(user), [setUser])
//     }
//     return <UserCacheContext.Provider value={context}>
//         {children}
//     </UserCacheContext.Provider>
// }

const AuthenticatedUserContext = React.createContext<AuthenticationScopeData>(DEFAULT_SCOPE);

export const AuthenticationScope = ({children}: {children: React.ReactNode}) => {
    //const {user, setUser} = useCachedUser()
    const [user, setUser] = useState<User|undefined>()
    const navigate = useNavigate()
    const {ipAddress, browserInfo} = useBrowserDescription()

    const path = () => window?.location?.pathname

    // Callbacks

    const resolveUserId = useCallback(() => {
        return user?.id
    }, [user?.id])

    const login = useCallback((user: User, cold=true) => {
        if( user ) {
            sessionStorage.setItem("ENGSCH_AUTH", JSON.stringify(user));
        }

        LOG.info("LOGIN", user?.email)
        trackAttributes({
            userDomain: user?.domain,
            uid: ["###", user?.domain, user?.email].join("")
        })
        setUser({
            ...user,
            roles: user?.roles?.map(role => role?.toLowerCase())
        })

        if( cold ) {
            navigate("/")
        } else if( path().startsWith("/logout") ) {
            navigate(-1) // Go back to where we were before being forced to logout
        }
    }, [navigate, setUser])

    const logout = useCallback(() => {
        sessionStorage.removeItem("ENGSCH_AUTH");

        resetApolloCache();

        setUser(undefined);

        if( ! path().startsWith("/logout") ) {
            navigate("/logout", {
                state: {
                    message: "You have been logged out."
                }
            })
        }
    }, [navigate, setUser])

    const isAuthenticated = useCallback(() => {
        return user && (user.expires > (Date.now() / 1000));
    }, [user])

    const authenticateWithToken = useCallback(async (auth: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        
        LOG.debug("Retrieving token for google credentials", auth)

        const ourAuth = await retrieveAuthToken(auth, { browser: browserInfo, ipAddress });

        if( ourAuth ) {
            login(ourAuth);
            LOG.debug("Authenticated: ", ourAuth);
        } else {
            LOG.error("Failed to authenticate and retrieve token.");
            navigate("/login/failed", {
                state: {
                    message: "We were unable to verify your credentials."
                }
            })
        }
    }, [browserInfo, ipAddress, login, navigate])

    // Effects

    // Handle resuming users.
    useEffect(() => {
        const currentUTCSeconds = Date.now() / 1000;

        // If there is no user or the user is expired
        if (user === undefined || (user.expires < currentUTCSeconds)) {
            let storedUser = JSON.parse(sessionStorage.getItem("ENGSCH_AUTH") || "{}") as User
            if (storedUser?.email) {
                LOG.debug("Stored user is:", storedUser);

                if (storedUser?.expires > currentUTCSeconds) {
                    LOG.info(`Resuming user session for ${storedUser?.email}`);
                    login(storedUser, false);
                } else {
                    LOG.info(`Expired user session for ${storedUser?.email}`);
                    logout();
                }
            } else {
                navigate("/login")
            }
        }
    }, [login, logout, navigate, user]);

    // Setup timer for auto logout / invalidation
    useEffect(() => {
        const currentUTCSeconds = Date.now() / 1000;
        
        const doExpiredLogout = () => {
            LOG.warn("Logged user out after inactivity", user?.id);
            try {
                navigate("/logout/expired", {state: {message: "Your session has expired and you have been logged out."}})
                logout();
            } catch(err) {
                // ignore failures here.
            }
        }

        const setupExpirationTimer = () => {
            if( user?.expires ) {
                const secondsUntilExpiration = user?.expires - currentUTCSeconds;
                LOG.debug("Setting up auto-logout for", secondsUntilExpiration, "seconds.");
                return setTimeout( doExpiredLogout, secondsUntilExpiration * 1000);
            } else {
                return setTimeout( doExpiredLogout, 1); // Logout the user now.
            }
        }

        if( user?.expires && user.expires > currentUTCSeconds ) {
            const timeout = setupExpirationTimer();
            return (() => clearTimeout(timeout));
        } else if( user?.expires && user.expires <= currentUTCSeconds ) {
            doExpiredLogout()
        } else {
            return undefined;
        }

    }, [user?.id, user?.expires, login, logout, isAuthenticated, navigate]) //TODO: This might be bad with the function reference in useEffect


    let context = {
        isAuthenticated,
        login,
        logout,
        authenticateWithToken,
        user,
        roles: user?.roles || [],
        hasRole: (role: SupportedRoles) => (user?.roles || []).includes(role?.toLowerCase()),
        userId: resolveUserId()
    }

    LOG.debug("AuthenticationScope updated", context);

    return (
        <AuthenticatedUserContext.Provider value={context}>
            { children }
        </AuthenticatedUserContext.Provider>
    )
}

/**
 * Custom hook to expose the authenticated information.
 * 
 */
export function useAuthenticated() {
    return useContext(AuthenticatedUserContext);
}

export const Authenticated = ({children}: {children: React.ReactNode}): React.ReactNode => {
    const {isAuthenticated} = useAuthenticated();

    if( isAuthenticated() ) {
        return children
    } else {
        return null
    }
}

export const Unauthenticated = ({children}: {children: React.ReactNode}): React.ReactNode => {
    const {isAuthenticated} =  useAuthenticated();

    if( isAuthenticated() ) {
        return null
    } else {
        return children
    }
}


export const LoginLogoutButton = () => {
    const {authenticateWithToken, logout, isAuthenticated} = useContext(AuthenticatedUserContext);

    const navigate = useNavigate()

    if (isAuthenticated()) {
        return(
            <GoogleLogout
                clientId={CLIENT_ID}
                buttonText="Logout"
                className="googleLogin"
                onLogoutSuccess={logout}/>
        )
    } else {
        return (
            <GoogleLogin
                clientId={CLIENT_ID}
                buttonText="Login"
                className="googleLogin"
                cookiePolicy={'single_host_origin'}
                onSuccess={authenticateWithToken}
                onFailure={(message) => {
                    LOG.error("Google login failed.", message)
                    navigate("/login/failed", {
                        state: {
                            message: "Google login failed. Please try again."
                        }
                    })
                }} />
        );
    }
}

export const LoggedInUserInfo = () => {
    const {user} = useAuthenticated();

    return (<span>{user?.email}</span>)
}


export const useImpersonateUser = () => {
    const {user, isAuthenticated, login} = useAuthenticated();

    const doImpersonation = async (email: string) => {
        if( ! isAuthenticated() || user === undefined ) {
            throw new Error("Unauthenticated...");
        }

        resetApolloCache();

        const domain = email.split("@")[1];
        const impersonatedAuth = await retrieveImpresonationToken(user, email, domain);

        if( impersonatedAuth ) {
            login(impersonatedAuth);
            LOG.info(`Impersonated: ${email} at ${domain}`);
        } else {
            LOG.error(`Failed to impersonate ${email} at ${domain}, no token granted.`);
        }
    }

    return doImpersonation
}

function resetApolloCache() {
    (async () => {
        try {
            graphQLClient().stop();
            await graphQLClient().clearStore();
        } catch (err) {
        }
    })();
}


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    LoginLogoutButton,
    LoggedInUserInfo,
    AuthenticationScope,
    Authenticated,
    Unauthenticated,
    useAuthenticated,
    useImpersonateUser
};

