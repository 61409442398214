import React, { ReactNode, useContext } from 'react'
import gql from "graphql-tag";
import { useAuthenticatedQuery } from './FetchHooks';
import { useOrganizationalScope } from './OrganizationalScope';
import { useAuthenticated } from './Auth';
import * as LOG from 'loglevel';

// CVU - dB6M8ERWMsYDyfUf5A5wS Ti9k-KmEraUL8EcfO6s1B
// lprDUkxfbWpXa8dnU0B0I demo.engaged.school

// -qiVSjymQpqYosiUKv6_H em2li_-DayE4JytEI3gxt  BFA-Fairfax        s

export enum Flags {
    FOCUS_SCORE = "FOCUS_SCORE",
    ADVISOR_NOTES = "ADVISOR_NOTES",
    EXPERIMENTS = "EXPERIMENTS"
}
// See https://www.typescriptlang.org/docs/handbook/enums.html#enums-at-compile-time
type FeatureFlagName = keyof typeof Flags

// Most of the filtering happens server side with permissions on the features entity. OrgUnitId bleeds through.
const FEATURES_QUERY = gql`
    query ActiveFeatures($unitId: String) {
        features(where: {_or: [
            {orgUnitId: {_is_null: true}}
            {orgUnitId: {_eq: $unitId}}
      ]}) {
        name
      }
    }
`

interface FlagContext {
    active: (flag: string) => boolean
}

const FeatureFlagContext = React.createContext<FlagContext>({
    active: (flag: string) => false
})

export const FeatureFlagScope = ({children}: {children: ReactNode | ReactNode[]}) => {

    const {currentUnitId} = useOrganizationalScope()
    const {hasRole} = useAuthenticated()

    const {data} = useAuthenticatedQuery(FEATURES_QUERY, undefined, {
        variables: {
            unitId: currentUnitId
        },
        skip: hasRole("guest") || currentUnitId === undefined
    })

    let flags = Object.fromEntries(data?.features?.map((feature: Record<string,any>) => [feature.name, true]) || [])

    let context: FlagContext = {
        active: (flag: string) => flags[flag] || false
    }

    LOG.debug("FeatureFlagScope updated", flags)

    return <FeatureFlagContext.Provider value={context}>
        {children}
    </FeatureFlagContext.Provider>
}

export function useFeatureFlag(...input: FeatureFlagName[]) {
    let flagContext = useContext(FeatureFlagContext)

    if( input.length === 1 ) {
        return flagContext.active(input[0])
    } else {
        return input.map(name => flagContext.active(name))
    }

}
