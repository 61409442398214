import React, { useCallback, useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, useLocation, Routes, Outlet } from 'react-router-dom';
import { Menu, Message, Container, Image, Segment, List, Dropdown, DropdownItem, Button, Sidebar, Icon} from 'semantic-ui-react';
import { ErrorBoundary } from 'react-error-boundary'

import * as LOG from 'loglevel';

import { ApolloProvider } from '@apollo/client';
import { graphQLClient } from './Config'

// Removing semanitc-ui-css and replacing it with the CDN version because of https://github.com/Semantic-Org/Semantic-UI-CSS/issues/75
//import 'semantic-ui-css/semantic.min.css';
import 'react-dropzone-uploader/dist/styles.css'
import './App.css';

import { LoginLogoutButton, LoggedInUserInfo, AuthenticationScope, Authenticated, useAuthenticated } from './components/Auth';
import { OrganizationallyPathed, OrganizationalScope, OrgAwareLink, ScopeMenu } from "./components/OrganizationalScope";
import { LandingPageMenu } from './views/LandingPageMenu';
import { PersonSearch } from './components/PersonSearch';

import { useLocationChangeEvent } from './components/Track';
import { useMediaStyles } from './components/MediaStyles';

// Our loadable bundles - should reduce initial bundle size and keep things snappier for each sub-function
import loadable from '@loadable/component';
import { FeatureFlagScope } from './components/FeatureFlags';
const SurveyView = loadable(() => import('./views/SurveyView'));
const AdvisorView = loadable(() => import('./views/AdvisorView'));
const AdminView = loadable(() => import('./views/AdminView'));
const DashboardView = loadable(() => import('./views/DashboardView'));
const ScratchpadView = loadable(() => import('./views/ScratchpadView'));

// Set up the logger.
LOG.setLevel("trace");

const GRAPHQL_CLIENT = graphQLClient();

const TRACKED_ROUTES = [
  "/",
  "/admin",
  "/admin/demo",
  "/admin/tweaks",

  "/ask",
  "/ask/:surveyId",

  "/advisory/:advisoryId/student",
  "/advisory/:advisoryId/student/:studentId",

  "/dashboard",
  "/dashboard/",
  "/dashboard/completion",
  "/dashboard/heatmap",

  "/logout",
  "/login",
  "/login/failed",
  "/logout/expired"
];

const App = () => {

  const { mediaClassName } = useMediaStyles()

  useNewRelicBrowserTracking()

  const [sidebarVisible, setSidebarVisible] = useState(false)

  console.log("App rendering")

  const ErrorFallback = ({error, resetErrorBoundary}) => (
    <Segment>
      <Message>
        <Message.Header>Something went wrong...</Message.Header>
        <p>This wasn't supposed to happen, but there was an error in the application.</p>
        <p>Details: {error.message}</p>
        <p><Button onClick={resetErrorBoundary}>Try again</Button></p>
      </Message>
    </Segment>
  )

  const toggleSidebar = (event) => {
    (event || window.event).stopPropagation();
    setSidebarVisible(!sidebarVisible)
  }

  return (
    <div className={`app ${mediaClassName}`}
      style={{
        // requires overflow: hidden; height: 100%; on html body #root in global css
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}>
      <div style={{
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
      }}>

        <TopMenu toggleSidebar={toggleSidebar} />
        <Sidebar.Pushable>
          <Container fluid style={{ marginTop: '5em' }}>
            <ErrorBoundary FallbackComponent={ErrorFallback} >
              <Routes>
                <Route path="/logout" element={<NotLoggedIn />}/>
                <Route path="/logout/expired" element={<NotLoggedIn />}/>
                <Route path="/login" element={<NotLoggedIn />}/>

                <Route path="/ask/*" element={<SurveyView />}/>

                <Route path="/*" element={
                  <MainApp sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />
                }/>

              </Routes>

              <Outlet />
            </ErrorBoundary>
          </Container>
        </Sidebar.Pushable>

      </div>
      <Footer />
    </div>
  );
};

const MainApp = ({sidebarVisible, toggleSidebar}) => {
  const {hasRole, user} = useAuthenticated()

  LOG.debug("MainApp rendering", user)

  // If they are a student, go to the surveys screen.
  if( hasRole("student") ) {
    return (<Navigate to={{ pathname: `/ask`, state: { returnTo: `/` } }}>Student Survey</Navigate>)
  }

  let AALayout = () =>
    <div className='authApp'>
      <SidebarMenu sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar}/>
      {(hasRole("admin") || hasRole("staff")) ? <div style={{ marginBottom: '2em' }}><ScopeMenu /></div> : null}
      <Outlet />
    </div>


  let OrgRoutes = ({children}) => {

    return <OrganizationallyPathed>
      <Routes>
        <Route path="o/:orgId/u/:unitId/r/:rosterId/s/:surveyId/*" element={children}/>
        <Route path="*" element={children}/>
      </Routes>
      <Outlet/>
    </OrganizationallyPathed>
  }

  return <OrgRoutes>
    <Routes>
      <Route element={<AALayout />}>
        <Route index element={
          <LandingPageMenu />
        } />

        <Route path="advisory/:advisoryId/*" element={
          <AdvisorView />
        } />

        <Route path="admin/*" element={
          <AdminView />
        } />

        <Route path="dashboard/*" element={
          <DashboardView />
        } />

        <Route path="scratchpad/*" element={
          <ScratchpadView />
        } />

        <Route path="o/*" element={
          <div>Path match o</div>
        } />

        <Route path="*" element={
          <div>No path match</div>
        } />
      </Route>
    </Routes>
  </OrgRoutes>
}


const SidebarMenu = ({sidebarVisible, toggleSidebar}) => {
  const {hasRole} = useAuthenticated()

  const MenuLink = ({to,icon,children}) => {
    return <Menu.Item as={OrgAwareLink} to={to} onClick={toggleSidebar}><Icon name={icon} size="mini"/>{children}</Menu.Item>
  }

  return (<Sidebar
    as={Menu}
    animation='overlay'
    icon='labeled'
    inverted
    vertical
    visible={sidebarVisible}
    width='thin'
    style={{ marginTop: '5em' }}>
      <Menu.Item style={{height: "4em"}}>&nbsp;</Menu.Item>
      <MenuLink to="/" icon="home">Home</MenuLink>
      <MenuLink to="/dashboard" icon="line graph">Dashboard</MenuLink>
      <MenuLink to="/advisory/_/student" icon="clipboard check">Advisory</MenuLink>
      {hasRole("admin") ? (<MenuLink to="/admin" icon="cogs">Admin</MenuLink>) : null}
  </Sidebar>)
};


const TopMenu = ({toggleSidebar}) => {
  const {hasRole} = useAuthenticated()

  return (<Menu fixed='top' inverted>
    <Menu.Item style={{paddingLeft: "0.6em", paddingRight: "0.3em"}}><Icon name='bars' size='large' onClick={toggleSidebar} alt="Side Menu"/> </Menu.Item>
    <Menu.Item as={OrgAwareLink} to='/' header>
      <Image size='mini' src='/icons/icon-72x72.png?v=1' style={{ marginRight: '1em' }} alt="Engage Logo"/>
      <p style={{fontSize: "18px"}}>engage</p>
    </Menu.Item>
    
    <Menu.Menu position='right'>
      {(hasRole("admin") || hasRole("leadership") || hasRole("counselor")) ? <Menu.Item header ><PersonSearch/></Menu.Item> : null}
      <Menu.Item header><UserMenu /></Menu.Item>
    </Menu.Menu>
  </Menu>)

}

const UserMenu = () => {
  const {user} = useAuthenticated();

  return (
    <Authenticated>
      <Dropdown text={user?.email}>
        <Dropdown.Menu>
          <DropdownItem><LoggedInUserInfo/></DropdownItem>
          <Dropdown.Item><LoginLogoutButton name='logout' /></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Authenticated>
  );
}


const Footer = () => {
  const {isMobile, isTiny} = useMediaStyles()

  return (<Segment inverted vertical className="footer" textAlign="center">
      <List horizontal inverted divided link size='small' className="footer-menu">
        <List.Item as='a' href="https://www.engaged.school/">Engaged.school</List.Item>
        {isMobile ? null : <List.Item as='a' href="https://www.engaged.school/contact/">Contact Us</List.Item>}
        {isTiny ? null : <List.Item as='a' href='#'>Usage Policy</List.Item>}
        {isTiny ? null : <List.Item as='a' href='#'>Privacy Policy</List.Item>}
      </List>
  </Segment>)
}

const NotLoggedIn = () => {
  const location = useLocation()
  return (<Container text style={{ marginTop: '5em' }}>
    
    {location?.state?.message && <Message warning>
      <Message.Header>{location?.state?.message}</Message.Header>
    </Message>}

    <Message warning size='big'>
      <Message.Header>Please Log In with your school Google user.</Message.Header>
      <p>You need to log in with the Google user you use for your school applications.</p>
      <p align="center"><LoginLogoutButton name='login' /></p>
    </Message>
  </Container>)
}

const useNewRelicBrowserTracking = () => {

  const newRelicTracker = useCallback((event) => {
    if( ! window.newrelic ) return

    if( event.pattern ) {
      window.newrelic.setCurrentRouteName(event.pattern)
    } else {
      window.newrelic.interaction().setName(event.pathname).save()
    }

    // Add all of the url variables to the interaction.
    Object.entries(event.variables || {})
      .map( ([key, value]) => window.newrelic.interaction().setAttribute(key, value))
      
  }, [])

  // Track SPA route changes and the like
  useLocationChangeEvent(TRACKED_ROUTES, newRelicTracker)
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => (
  <ApolloProvider client={GRAPHQL_CLIENT}>
    <Router>
      <Routes>
        <Route path="/index.html" element={<Navigate to="/" />} />

        <Route index path="/*" element={
          <AuthenticationScope>
            <OrganizationalScope>
              <FeatureFlagScope>
                <App/>
              </FeatureFlagScope>
            </OrganizationalScope>
          </AuthenticationScope>
        }/>
      </Routes>
    </Router>
  </ApolloProvider>
)
