import React from 'react';
import { Link } from 'react-router-dom';
import { Message, List, Header, Container } from 'semantic-ui-react';
import { useAuthenticated } from '../components/Auth';
import { OrgAwareLink } from '../components/OrganizationalScope';


export const LandingPageMenu = () => {

    const { user, hasRole } = useAuthenticated();

    const renderAdvisorLink = () => {
        if (user && hasRole("advisor")) {
            return (<Message.Item>
                <OrgAwareLink to={`/advisory/_/student`}>Advisor Student Engagement Portal</OrgAwareLink>
            </Message.Item>);
        }
    };

    const renderStudentSurveyLink = () => {
        if (user && hasRole("participant")) {
            return (<Message.Item>
                <Link to="/ask">Student Survey</Link>
            </Message.Item>);
        }
    };

    const renderAdminMenuLink = () => {
        if (user && hasRole("admin")) {
            return <Message.Item><OrgAwareLink to="/admin">Admin Controls</OrgAwareLink></Message.Item>;
        }
    };

    const renderDashboardLink = () => {
        if (user && (hasRole("admin") || hasRole("staff"))) {
            return (<Message.Item>
                <OrgAwareLink to={`/dashboard`}>Completion Dashboard</OrgAwareLink>
            </Message.Item>);
        }
    };

    const renderGuest = () => {
        return (
            <Message>
                <Message.Header>Welcome {user?.firstName} {user?.lastName}</Message.Header>
                <Message.Content>
                    <p />
                    <Header.Subheader as={"h3"}>The user you logged in with does not have access to the Engaged.school portal.</Header.Subheader>
                    <p>If your school uses Engaged.school please make sure you have logged in with your 
                        school's Google account.</p>
                    <List>
                        <List.Item>Email: {user?.email}</List.Item>
                        <List.Item>Name: {user?.firstName} {user?.lastName}</List.Item>
                    </List>

                    <p>If you would like to learn more or for your school or organization to leverage the
                    Engaged.school platform, please <a href="https://www.engaged.school/contact">contact us</a>.</p>
                </Message.Content>
            </Message>
        )

    }

    if( hasRole("guest") || user?.roles?.length === 0) {
        return <Container>{renderGuest()}</Container>
    } else {
        return (<Container><Message>
            <Message.Header>Portal Links</Message.Header>
            <Message.List>
                {renderStudentSurveyLink()}
                {renderAdvisorLink()}
                {renderDashboardLink()}
                {renderAdminMenuLink()}
            </Message.List>
        </Message></Container>);
    }
};

